@import 'variables';

.event-content {
    height: 100%;
    padding-top: 150px;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(246, 253, 255, 1) 0%, rgba(115, 177, 218, 1) 100%);
    /* background-image: url('../images/homepageBackground.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: relative;
    background-attachment: fixed; */
}

.title {
    position: relative;
    align-items: center;
    font-size: 50px;
    font-family: 'Times New Roman', serif;
    margin-bottom: 25px;
    background-color: white;
    height: 100px;
    width: 100%;
}

.event-container {
    display: flex;
    justify-self: center;
    align-items: center;
    text-align: center;
    padding-bottom: 36px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;

}

.event-container p {
    font-family: 'Times New Roman', serif;
    flex-direction: row;
    font-size: 20px;
    margin: 20px;
    background-color: transparent;
}

.event {
    position: relative;
    background-color: $primary-light;
    margin: 50px;
}

.event-header {
    font-size: 50px;
    margin: 50px;
}

.event-info {
    margin-bottom: 50px;
    line-height: 35px;
    margin-left: 50px;
}
.event-flyer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.eventImage {
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, .5);
    margin-top: 20px;
    margin: 50px;
    width: 420px;
}

.sponsor {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-color: $primary-light;
    margin-bottom: 100px;
}

.event-disclaimer {
    margin-top: 10px;
    font-size: 12px;
    font-style: italic;
}

.event-map {
    width: 80%;
    margin-bottom: 40px;
}

.btn {
    display: inline-block;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    background-color: rgb(255, 170, 151);
    color: #333;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: box-shadow 0.3s;
    box-shadow: 0 0 0 transparent;
    transition: background-color 0.5s;
    margin-top: 50px;
    margin-bottom: 50px;
}

.btn:hover {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: rgb(255, 203, 184);
}

.btn:not(:hover) {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-image {
    margin-right: 15px;
}

@media only screen and (max-width: 768px) {
    .event-content {
        padding-top: 70px;
    }

    .event-container {
        flex-direction: column;
        text-align: center;
    }

    .event-header {
        font-size: 25px;
    }

    .event-info {
        text-align: center;
        margin: 10px;
        width: 90%;
    }

    .event {
        margin: 15px;
    }

    .eventImage, .event-map {
        width: 90%;
        height: 90%;
    }

    .sponsor {
        padding: 10px;
        background-color: $primary-light;
    }
}