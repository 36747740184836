*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;

}
.pageContent{
  padding-top: 200px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.allContent {
  /* background-image: url('../images/homepageBackground.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%; */

}
.intro{
  flex-direction: row;
}
.footer{
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;

}
.app{
  height: 100%;
  width: 100%;
  position: relative;
}

a{
  color: inherit;
}
@media only screen and (max-width: 768px) {
  .app{
    width:100%;
  }
}
