@import 'variables';

.page-title {
    padding-top: 150px;

}

.header {
    background-image: url('/images/svgs/hero_aboutUs.svg');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 150px;
    padding-bottom: 50px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    // border-bottom-left-radius: 60% 10%;
}

.divider {
    // background-color: $secondary-dark;
    // border-bottom-right-radius: 90% 20%;
    // background-image: url('/images/svgs/divider.svg');
    // background-size: cover;
    // background-position: bottom;
    // background-repeat: no-repeat;
    height: 200px
}

.aboutUs-header {
    background-color: white;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
    ;
    flex-direction: column;
    padding-top: 20px;
    font-family: 'Times New Roman', serif;
    border-radius: 25px;
    margin-top: 100px;
    margin-bottom: 10px;
    font-size: 29px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 2px;
    width: 60%;
    height: 60%;

}

.bakground {
    z-index: 0;
}

.our-mission {
    padding-top: justify;
    font-size: 30px;
    font-style: italic;
    align-items: center;
}

.mission {
    font-size: 20px;
    letter-spacing: normal;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 20px;
}

.meet {
    color: black;
    padding-top: 50px;
}

.emojis {
    flex-direction: row;
    z-index: 5;
    position: relative;
}

.meet-our-team {
    // border-top-left-radius: 70% 20%;
    // border-bottom-right-radius: 50% 40%;
    background-color: $secondary-dark;
    font-family: 'Times New Roman', serif;
    position: relative;
    font-size: 29px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 2px;
}

.boardMembers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    // border-top-left-radius: 10% 20%;
}

.card {
    border-radius: 25px;
    height: 500px;
    padding-top: 50px;
    line-height: 24px;
    background-color: white;
    letter-spacing: normal;
    font-family: 'Times New Roman', serif;
    display: flex;
    width: 450px;
    padding: 20px;
    /* border: 1px solid black; */
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);

    margin: 25px;
    align-items: center;
    position: top;
    padding: 8px;
}

// .card:hover {
//     transform: translateY(-5px);
// }

.image-information {

    flex-direction: row;
    width: 250px;
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
    border-spacing: 10px;
    margin: 10px;

}

.name {
    font-weight: bold;
    font-size: 16px;
}

.position {
    font-weight: bold;
    font-size: 12px;
}

.employment {
    font-style: italic;
    font-size: 12px;
    line-height: 20px;
}

.bio {
    line-height: 20px;
    text-align: left;
    color: black;
    font-size: 19px;
    margin-top: 8px;
}

.headshot-container {
    width: 125px; 
    height: 160px; 
    overflow: hidden; 
    display: flex; 
    align-items: center; 
    justify-content: center;
    // border-radius: 10px;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.headshot {
    width: 100%;
    height: auto;
    object-fit: cover;
}

@media only screen and (max-width: 768px) {
    .aboutUs-header {
        width: 90%;
        padding: none;
    }

    .mission {
        font-size: 15px;
        line-height: 17px;

    }

    .header {
        background-size: cover;
        padding-top: 70px;
    }

    .meet {
        padding-top: 20px;
        margin: 0;
    }
    .divider {
        height: 100px
    }
    .card {
        width: 350px;
        position: relative;
    }

    #members {
        width: 100%;

    }

    .bio {
        font-size: 13px;
        line-height: normal;
    }

    .emoji {
        height: 50px;
        width: 75px;
    }
}