.carousel{
    max-width: fit-content;
    position: relative;
    margin: auto;
    margin-top: inherit;
    align-items: center;
}
.img{
    align-items: center;
    size: relative;
}
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;   
    color: black;
    font-weight: bold;
    font-size: 28px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    
  }
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  .next, .prev {
    padding: 4px;
    background-color: lightgray;
    opacity: .5;
  }
  .prev{
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 50%;
    color: black;
    font-weight: bold;
    font-size: 28px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }
  .image-info{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    align-items: center;
    width: 35%;
    height: 40px;
    position: absolute;
    bottom: 2px;
    font-size: 17px;
    background-color: rgb(255, 203, 184);
    box-shadow: 0 1px 7px 2px rgb(110, 144, 175);
    left:0;
    opacity: .5;
  }
  .image-info:hover {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    opacity: 1;
  }
  
  .image-info:not(:hover) {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }